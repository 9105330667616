<template>
  <div class="layout-product-details">
    <div class="container">
      <div class="left">
        <div class="text rich-text">
          <slot name="text"></slot>
        </div>
      </div>
      <div class="right">
        <layout-product-details-tabs>
          <slot :slot="this.$store.state.langs.TABS_DETAIL_ID_SIZE" name="sizestab"></slot>
          <slot :slot="this.$store.state.langs.TABS_DETAIL_ID_TESTIMONIALS" name="testimonialstab"></slot>
          <slot :slot="this.$store.state.langs.TABS_DETAIL_ID_SHOWROOMS" name="showroomstab"></slot>
          <slot :slot="this.$store.state.langs.TABS_DETAIL_ID_FEATURES" name="featurestab"></slot>
        </layout-product-details-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import layoutProductDetailsTabs from '@/components/layout/product/layout-product-details-tabs'
export default {
  components: {
    layoutProductDetailsTabs
  },
    methods: {

    },
    mounted() {
      //console.log("sizes details", this.$store.state.langs.TABS_DETAIL_ID_SIZE );
    },
}
</script>

<style lang="scss" scoped>
.layout-product-details {
  margin-top: 2em;
  padding-bottom: 5em;
}
.container {
  display: grid;
  /*grid-template-columns: repeat(2, 1fr);*/
  grid-template-columns: repeat( 2, minmax(250px, 1fr) );
  grid-gap: 3em;
}
@media screen and (max-width: 767px) {
  .container {
    display: block;
  }
}
</style>
